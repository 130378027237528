import Vue from 'vue';
import App from './App.vue';
import './tailwind.css';

Vue.config.productionTip = false;

// Create an event bus
export const EventBus = new Vue();

// Listen to hash changes in the URL
window.addEventListener('hashchange', () => {
  const id = window.location.hash.substr(1); // Get the part after the '#'
  EventBus.$emit('hashchange', id); // Emit an event with the new hash value
});

new Vue({
  render: (h) => h(App),
}).$mount('#app');
