<template>
  <div class="min-h-full">
    <div class="pb-32 bg-filament">
      <nav class="border-b border-indigo-300 border-opacity-25 lg:border-none">
        <div class="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
          <div
            class="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
            <div class="flex items-center px-2 lg:px-0">
              <div class="flex-shrink-0">
                <svg width="146" height="18" viewBox="0 0 166 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.968 17H6.104V11.384H18.824V8.408H6.104V4.856H21.296V1.208H0.968V17ZM23.858 3.68H28.61V0.535999H23.858V3.68ZM23.858 
                          17H28.61V5.144H23.858V17ZM31.7548 17H36.5068V0.199999H31.7548V17ZM47.1189 17.312C52.3749 17.312 54.9189 16.088 55.6869 14.384H55.7109C55.7109 15.152 
                          55.6389 16.16 55.5909 17H60.3429V10.256C60.3429 6.872 56.8869 4.832 49.7589 4.832C42.7749 4.832 39.7749 6.536 39.7749 8.816C39.7749 8.888 39.7749 9.08 
                          39.8229 9.296H44.2149C44.1909 9.2 44.1909 9.104 44.1909 9.08C44.1909 8.408 45.3909 7.664 49.3029 7.664C54.0549 7.664 55.5909 8.528 55.5909 
                          9.896V10.088H45.1989C40.7349 10.088 39.2469 11.6 39.2469 13.496C39.2469 15.2 40.8549 17.312 47.1189 17.312ZM48.4869 14.576C44.8629 14.576 43.6389 14 
                          43.6389 13.208C43.6389 12.512 44.6469 12.296 46.7589 12.296H55.5909V12.392C55.5909 13.304 53.0469 14.576 48.4869 14.576ZM62.9924 
                          17H67.7444V12.944C67.7444 9.896 70.0004 8.384 73.1204 8.384C76.2884 8.384 77.6804 9.656 77.6804 12.44V17H82.4324V12.968C82.4324 9.896 84.7124 8.384 
                          87.8324 8.384C91.0004 8.384 92.3684 9.656 92.3684 12.44V17H97.1204V11.072C97.1204 7.304 94.9844 4.832 89.7524 4.832C85.4804 4.832 83.1284 6.536 82.2644 
                          9.032H82.2164C81.5924 6.512 79.4564 4.832 75.0644 4.832C70.7924 4.832 68.4404 6.56 67.7204 9.128H67.6964L67.7444 5.144H62.9924V17ZM120.912 
                          10.952C120.912 7.112 117.648 4.832 110.52 4.832C103.512 4.832 99.4081 7.304 99.4081 11.192C99.4081 14.192 101.904 17.312 110.736 17.312C117.816 17.312 
                          120.696 15.368 120.912 12.992H116.688C116.424 13.784 114.96 14.48 110.76 14.48C106.176 14.48 104.616 13.4 104.256 11.936H120.912V10.952ZM116.688 
                          9.752H104.448C105.096 8.408 106.944 7.616 110.736 7.616C115.152 7.616 116.688 8.624 116.688 9.656V9.752ZM123.344 17H128.096V12.944C128.096 9.92 131.024 
                          8.384 135.176 8.384C139.64 8.384 141.392 9.704 141.392 12.44V17H146.144V11.192C146.144 7.16 143.648 4.832 137.288 4.832C131.84 4.832 129.056 6.416 
                          128.12 9.296H128.048L128.096 5.144H123.344V17ZM160.056 17.24C162.456 17.24 164.328 17.072 165.072 16.928V13.832C164.304 13.904 162.72 14.024 161.112 
                          14.024C156.336 14.024 155.544 13.544 155.544 12.344V8.36H165.168V5.144H155.544V2.456H151.656L151.44 4.088C151.344 4.904 151.056 5.144 150.336 
                          5.144H147.672V8.36H150.792V12.56C150.792 14.816 152.112 17.24 160.056 17.24Z" fill="#ff3c00" />
                </svg>
              </div>
              <div class="hidden lg:ml-10 lg:block">
                <div class="flex space-x-4">
                  <!-- Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" -->
                  <!-- <a href="#" class="bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium"
                    aria-current="page">Dashboard</a>

                  <a href="#"
                    class="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium">Team</a>

                  <a href="#"
                    class="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium">Projects</a>

                  <a href="#"
                    class="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium">Calendar</a>

                  <a href="#"
                    class="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium">Reports</a> -->
                </div>
              </div>
            </div>
            <div class="flex lg:hidden">
              <!-- Mobile menu button -->
              <button type="button"
                class="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                aria-controls="mobile-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <!-- Menu open: "hidden", Menu closed: "block" -->
                <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
                <!-- Menu open: "block", Menu closed: "hidden" -->
                <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="hidden lg:ml-4 lg:block">
              <div class="flex items-center">


                <!-- Profile dropdown -->
                <div class="relative ml-3 flex-shrink-0">


                  <!--
                  Dropdown menu, show/hide based on menu state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile menu, show/hide based on menu state. -->
        <div class="lg:hidden" id="mobile-menu">
          <div class="space-y-1 px-2 pb-3 pt-2">
            <!-- Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" -->
            <!-- <a href="#" class="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium"
              aria-current="page">Ad Placement Audit</a> -->

            <!-- <a href="#"
              class="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium">Team</a>

            <a href="#"
              class="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium">Projects</a>

            <a href="#"
              class="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium">Calendar</a>

            <a href="#"
              class="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium">Reports</a> -->
          </div>
          <div class="border-t border-indigo-700 pb-3 pt-4">
            <div class="flex items-center px-5">
              <!-- <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt="">
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-white">Tom Cook</div>
                <div class="text-sm font-medium text-indigo-300">tom@example.com</div>
              </div>
              <button type="button"
                class="ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                <span class="sr-only">View notifications</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
              </button> -->
            </div>
          </div>
        </div>
      </nav>
      <header class="py-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight" style="color: #2F4858">Ad Placement Audit</h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">


        <div class="bg-white shadow rounded-lg">

          <div class="px-6 p-6 border-b border-gray-200">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Main Findings</h3>
            <p class="mt-1 text-sm text-gray-500">These numbers show the main findings of the latest ad campaign.</p>
          </div>

          <dl class="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden  md:grid-cols-4 md:divide-x md:divide-y-0">

            <div v-for="item in stats" :key="item.name" class="px-4 py-5 sm:p-6">
              <dt class="text-base font-normal text-gray-900">{{ item.name }}</dt>
              <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div class="flex items-baseline text-2xl font-semibold filament-color">
                  {{ item.stat }}
                  <!-- <span class="ml-2 text-sm font-medium text-gray-500">from {{ item.previousStat }}</span> -->
                </div>

                <!-- <div
                  :class="[item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0']">
                  <ArrowUpIcon v-if="item.changeType === 'increase'"
                    class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  <ArrowDownIcon v-else class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true" />
                  <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
                  {{ item.change }}
                </div> -->
              </dd>
            </div>
          </dl>

        </div>

        <div class="flex gap-8">
          <div class="w-1/5 mt-10">

            <nav class="space-y-1" aria-label="Sidebar">
              <h2 class="text-base font-semibold leading-7 text-gray-900">Categories</h2>


              <a v-for="item in categories" :key="item.name" :href="item.href"
                :class="[item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'flex items-center rounded-md px-3 py-2 text-sm font-medium']"
                :aria-current="item.current ? 'page' : undefined" @click="triggerTab(item)">
                <!-- <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400', '-ml-1 mr-3 h-6 w-6 flex-shrink-0']" aria-hidden="true" /> -->
                <span class="truncate">{{ item.name }}</span>
                <span v-if="item.count"
                  :class="[item.current ? 'bg-gray-50' : 'bg-gray-200 text-gray-600', 'ml-auto inline-block rounded-full py-0.5 px-3 text-xs']">{{
                    item.count }}</span>
              </a>

            </nav>


            <!-- <nav class="space-y-1 mt-6" aria-label="Sidebar">
              <h2 class="text-base font-semibold leading-7 text-gray-900">Language</h2>
              <ul class="mt-1 px-2" id="sub-menu-2">
                <li>
                  <a href="#"
                    class="hover:bg-gray-50 block rounded-md py-2 pr-2 pl-3 text-sm leading-4 text-gray-700">English</a>
                </li>

                <li>
                  <a href="#"
                    class="hover:bg-gray-50 block rounded-md py-2 pr-2 pl-3 text-sm leading-4 text-gray-700">Spanish</a>
                </li>

                <li>
                  <a href="#"
                    class="hover:bg-gray-50 block rounded-md py-2 pr-2 pl-3 text-sm leading-4 text-gray-700">French</a>
                </li>

                <li>
                  <a href="#"
                    class="hover:bg-gray-50 block rounded-md py-2 pr-2 pl-3 text-sm leading-4 text-gray-700">German</a>
                </li>
              </ul>

            </nav> -->
          </div>

          <div class="w-4/5 mt-10">


            <!-- <div class=" overflow-hidden rounded-lg bg-white shadow">
              <div class="px-4 py-5 sm:p-6">
                <h2 class="font-semibold text-xl mt-2 leading-6 text-gray-900">Ad Waste</h2>
                <p class="mt-2 max-w-4xl text-sm text-gray-500">Ad waste on YouTube is a pressing concern for advertisers,
                  who need to be particularly cautious when targeting kids content or music videos that are primarily
                  being listened to rather than watched. By doing so, they can optimize their ad spend and ensure that
                  their marketing messages reach the intended audience, ultimately leading to more effective advertising
                  campaigns.</p>
              </div>
            </div> -->





            <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6">

              <div class="-mx-6">
                <nav class="-mb-px px-6 flex space-x-8 border-b border-gray-300 overflow-x-scroll">
                  <a v-for="tab in tabs" :key="tab.category" @click="activateTab(tab)" class="cursor-pointer"
                    :class="[tab.current ? 'border-gray-800 text-gray-800' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium']"
                    :aria-current="tab.current ? 'page' : undefined">{{ tab.category }} ({{ tab.channels_found }})</a>
                </nav>
              </div>

              <div class="-mx-6 border-b border-t">



                <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
                  <div
                    class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8 border-r">
                    <dt class="text-sm font-medium leading-6 text-gray-500">CTR</dt>
                    <dd class="text-xs font-medium text-gray-700">AVG +0%</dd>
                    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {{ displayCTR }}
                    </dd>
                  </div>

                  <div
                    class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8 border-r">
                    <dt class="text-sm font-medium leading-6 text-gray-500">CR</dt>
                    <dd class="text-xs font-medium text-gray-700">AVG +0%</dd>
                    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {{ displayCR }} </dd>
                  </div>

                  <div
                    class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8 border-r">
                    <dt class="text-sm font-medium leading-6 text-gray-500">Total Costs</dt>
                    <dd class="text-xs font-medium text-rose-600">AVG +0%</dd>
                    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {{ displayCosts }}</dd>
                  </div>

                  <div
                    class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                    <dt class="text-sm font-medium leading-6 text-gray-500">Average CPA</dt>
                    <dd class="text-xs font-medium text-rose-600">+0%</dd>
                    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {{ displayCPA }}
                    </dd>
                  </div>
                </dl>



              </div>

              <div class="-mx-6 py-2 flex justify-end	pr-4 bg-gray-200 shadow-sm ring-1 ring-gray-900/5 rounded">

                <div class="flex items-center">
                  <button type="button" :class="[
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
                    showFraud ? 'bg-indigo-600 focus:ring-indigo-600' : 'bg-gray-300',
                  ]" role="switch" :aria-checked="showFraud" aria-labelledby="annual-billing-label"
                    @click="showFraud = !showFraud">
                    <span aria-hidden="true" :class="[
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      showFraud ? 'translate-x-5' : 'translate-x-0',
                    ]"></span>
                  </button>
                  <span class="ml-3 text-sm" id="annual-billing-label">
                    <span class="font-medium text-gray-900">Compensate Fraud</span>
                  </span>
                </div>

                <button type="button"
                  class="ml-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Channel
                  Discovery</button>

              </div>

              <!-- <ul role="list" class="divide-y divide-gray-200" v-if="positions.length >= 1">


                <div class="-mx-6 pl-6 border-b mt-4 border-red-200 pb-5 bg-gradient-to-t from-red-100 via-transparent">
                  <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                    <h3 class="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">Likely Fraudulous</h3>
                    <p class="ml-2 mt-1 truncate text-sm text-gray-500">1</p>
                  </div>
                </div>


                <li v-for="position in positions" :key="position.id">
                  <div class=" py-4 flex justify-between">
                    <div class="flex flex-col">
                      <p class="truncate text-sm font-medium text-gray-600">{{ position.channel_name }}</p>
                      <p class="flex items-center text-sm text-gray-500">{{ position.language }} | CTR {{
                        parseInt(position.interactions / position.impressions * 100) }}% | CR {{
    parseInt(position.conversions / position.impressions * 100) }}% | $ {{ position.costs }}</p>

                    </div>
                    <div class="mt-2 sm:flex sm:justify-between">
                      <div class=" flex items-center text-sm text-gray-500 sm:mt-0">
                        <div class="bg-center rounded box-border transform transition ease-in-out hover:scale-125"
                          v-if="position.videos.length >= 1"
                          style="background-size: 105%; border: 1px solid rgb(255 255 255 / 32%); width: 80px; height: 45px;"
                          :style="`backgroundImage: url('https://img.youtube.com/vi/${position.videos[0]}/hqdefault.jpg')`">
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul> -->


              <ul role="list" class="divide-y divide-gray-200" v-if="positions.length >= 1">
                <template v-for="(group, header) in groupedPositions">
                  <div 
                  class="-mx-6 pl-6 border-b mt-6 pb-5 bg-gradient-to-t via-transparent"
    :class="{
      'border-red-200 from-red-100': header === 'likelyFraudulous',
      'border-green-200 from-green-100': header === 'performing',
      'border-gray-300 from-gray-200': header === 'poor'
    }"
                    :key="group" v-if="group.length">
                    <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                      <h3 class="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">{{ nameNormalizer(header) }}</h3>
                      <p class="ml-2 mt-1 truncate text-sm text-gray-500">{{ group.length }}</p>
                    </div>
                  </div>

                  <li v-for="position in group" :key="position.id">
                    <div class="py-4 flex justify-between">
                      <div class="flex flex-col">
                        <a :href="`https://www.youtube.com/channel/${position.channel_id}`" target="_blank"><p class="truncate text-sm font-medium text-gray-600">{{ position.channel_name }}</p></a>
                        <p class="flex mt-2 items-center text-sm text-gray-500">
                          <span class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ position.language == '' ? 'no lang' : position.language}}</span>
                          <span class="inline-flex ml-2 items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">CTR {{ parseFloat(position.interactions / position.impressions * 100).toFixed(2) + '%' }}</span>
                          <span class="inline-flex ml-2 items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">CR {{ parseFloat(position.conversions / position.impressions * 100).toFixed(2) + '%' }}</span>
                          <span class="inline-flex ml-2 items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">$ {{position.costs}}</span>
                        </p>


                      </div>
                      <div class="mt-2 sm:flex sm:justify-between">
                        <div class=" flex items-center text-sm text-gray-500 sm:mt-0">
                          <div class="bg-center rounded box-border transform transition ease-in-out hover:scale-125"
                            v-if="position.videos.length >= 1"
                            style="background-size: 105%; border: 1px solid rgb(255 255 255 / 32%); width: 80px; height: 45px;"
                            :style="`backgroundImage: url('https://img.youtube.com/vi/${position.videos[0]}/hqdefault.jpg')`">
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>



            </div>

            <!-- <div class="relative mt-6">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center">
                <button type="button"
                  class="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <svg class="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path
                      d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                  </svg>
                  Show 20 more (93 left)
                </button>
              </div>
            </div> -->


          </div>
        </div>


      </div>


    </main>
  </div>
</template>
  
<script>


export default {
  components: {
  },
  props: {
    reportData: Object
  },
  data() {
    return {
      showFraud: false,
      category_stats: {
        ctr: 0,
        cr: 0,
        spent: 0,
        effective_spent: 0
      },
      tabs: [
        { name: 'Kids Entertainment', href: '#', current: false },
        { name: 'Kids Gaming', href: '#', current: false },
        { name: 'Viral Video Compilations', href: '#', current: true },
        { name: 'Toys', href: '#', current: false },
        { name: 'Animation & Anime', href: '#', current: false },
      ],
      categories: [
        { name: 'Ad Waste', href: '#', icon: "HomeIcon", current: true, count: '40%', type: 'safe' },
        { name: 'Ad Waste', href: '#', icon: "HomeIcon", current: true, count: '40%', type: 'waste' },
        { name: 'Ad Waste', href: '#', icon: "HomeIcon", current: true, count: '40%', type: 'nonsafe' },
        { name: 'Ad Waste', href: '#', icon: "HomeIcon", current: true, count: '40%', type: 'nonsuitable' },
        // { name: 'Non Brand Safe', href: '#', icon: "UsersIcon", current: false, count: '4%' },
        // { name: 'Non Brand Suitable', href: '#', icon: "FolderIcon", current: false, count: '10%' },
        // { name: 'Safe', href: '#', icon: "FolderIcon", current: false, count: '34%' },
        // { name: 'Uncategorized', href: '#', icon: "FolderIcon", current: false, count: '12%' },
      ],
      navigation: [
        { name: "Dashboard", href: "#", current: true },
        { name: "Team", href: "#", current: false },
        { name: "Projects", href: "#", current: false },
        { name: "Calendar", href: "#", current: false },
        { name: "Reports", href: "#", current: false },
      ],
      userNavigation: [
        { name: "Your Profile", href: "#" },
        { name: "Settings", href: "#" },
        { name: "Sign out", href: "#" },
      ],
      positions: [],
      fraudMetrics: {
        impressions: 0,
        conversions: 0,
        interactions: 0,
        costs: 0
      }
    };
  },
  computed: {
    displayCTR() {
    const minusFraudInteractions = this.category_stats.interactions - this.fraudMetrics.interactions;
    const minusFraudImpressions = this.category_stats.impressions - this.fraudMetrics.impressions;

    let stats = this.showFraud
      ? minusFraudImpressions !== 0 ? (minusFraudInteractions / minusFraudImpressions * 100).toFixed(2) : '—'
      : parseFloat(this.category_stats.ctr).toFixed(2);

    return isNaN(stats) ? '—' : stats + '%';
  },
  displayCR() {
    const minusFraudConversions = this.category_stats.conversions - this.fraudMetrics.conversions;
    const minusFraudImpressions = this.category_stats.impressions - this.fraudMetrics.impressions;
    console.log('numbers')

    console.log('category_stats.impressions:', this.category_stats.impressions);
    console.log('fraudMetrics.impressions:', this.fraudMetrics.impressions);


    console.log(minusFraudConversions);
    console.log(minusFraudImpressions);

    let stats = this.showFraud
      ? (parseFloat(minusFraudConversions) / parseInt(minusFraudImpressions) * 100).toFixed(2) 
      : parseFloat(this.category_stats.cr).toFixed(2);

      console.log(stats);

    return isNaN(stats) ? '—' : stats + '%';
  },
    displayCosts() {
      const spent = parseFloat(this.category_stats.spent);
      const cost = parseFloat(this.fraudMetrics.costs);
      const numerator = spent - cost;

      let costs = this.showFraud
      ? numerator.toFixed(2)
      : this.category_stats.spent;

      return isNaN(costs) ? '—' : '$' + costs;
    },
    displayCPA() {
    const spent = parseFloat(this.category_stats.spent);
    const cost = parseFloat(this.fraudMetrics.costs);
    const numerator = spent - cost;
    const denominator = this.category_stats.conversions - this.fraudMetrics.conversions;

    let cpa = this.showFraud
      ? (numerator / denominator).toFixed(2)
      : parseFloat(this.category_stats.avg_cpa).toFixed(2);

    return isNaN(cpa) ? '—' : '$' + cpa;
  },
    groupedPositions() {
    let fraudulous = [];
    let performing = [];
    let poor = [];
    let lackingMetadata = [];

    this.positions.forEach((position) => {
      if (position.impressions === 0 && position.conversions === 0) {
        lackingMetadata.push(position); 
      }
      if (position.impressions) {
        const interactionRatio = position.interactions / position.impressions;
        const conversionRatio = position.conversions / position.impressions;
        const ctr = position.interactions / position.impressions;
        const cr = position.conversions / position.impressions;

        const isInteractionFraudulous = Math.abs(interactionRatio - conversionRatio) <= 0.25 && position.costs > 30 && ctr >= 0.7 && cr >= 0.7;
        const isConversionFraudulous = Math.abs(interactionRatio - conversionRatio) <= 0.25 && position.costs > 30 && ctr >= 0.7 && cr >= 0.7;
        const isHighCRAndSpent = cr > 0.50 && position.costs > 20;

        if (isInteractionFraudulous || isConversionFraudulous || isHighCRAndSpent) {
          fraudulous.push(position);

          this.fraudMetrics = {
            impressions: this.fraudMetrics.impressions + position.impressions,
            conversions: this.fraudMetrics.conversions + position.conversions,
            costs: this.fraudMetrics.costs + position.costs,
            interactions: this.fraudMetrics.interactions + position.interactions,
          }

        } else if (cr > 0.005) {
          performing.push(position);
        } else {
          poor.push(position);
        }
      }
    });

    const sortByCostsDesc = (a, b) => b.costs - a.costs;

    console.log('impressions')
    console.log(this.fraudMetrics)


    return {
      likelyFraudulous: fraudulous.sort(sortByCostsDesc),
      performing: performing.sort(sortByCostsDesc),
      poor: poor.sort(sortByCostsDesc),
      lackingMetadata: lackingMetadata.sort(sortByCostsDesc),
    };
  },


    stats() {
      return [
        { name: 'Ad Waste', stat: this.findPercentage('waste'), previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
        { name: 'Non Brand Safe', stat: this.findPercentage('nonsafe'), previousStat: '70,946', change: '12%', changeType: 'increase' },
        { name: 'Non Brand Suitable', stat: this.findPercentage('nonsuitable'), previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
        { name: 'Total Placements Analyzed', stat: this.reportData.analysis_metadata.channels_analysed, previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
      ];
    }
  },
  methods: {
    nameNormalizer(name) {
      if (name === 'likelyFraudulous') return 'Likely Fraudulent'
      if (name === 'performing') return 'Performing'
      if (name === 'poor') return 'Poorly Performing'
    },
    findPercentage(type) {
      if (this.categories) {
        const category = this.categories.find(item => item.type === type);
        if (category) {
          console.log(category)

          return `${parseInt((category.count / this.reportData.analysis_metadata.channels_analysed) * 100)}%`;
        } else {
          return '0%'
        }

      }
    },
    triggerTab(obj) { // showing main categories
      this.tabs = obj.categories;

    },
    activateTab(obj) { // showing channels
      this.fraudMetrics = {
        impressions: 0,
        conversions: 0,
        interactions: 0,
        costs: 0
      }
      const tabIndex = this.tabs.findIndex((tab) => tab.id === obj.id);

      // Set the 'current' key to true for the found object
      if (tabIndex !== -1) {
        // First, set 'current' to false for all tabs
        this.tabs.forEach((tab) => {
          tab.current = false;
        });

        // Then, set 'current' to true for the selected tab
        this.$set(this.tabs[tabIndex], 'current', true);
      }

      this.positions = [];

      this.category_stats = {
        ctr: parseFloat(obj.interactions / obj.impressions * 100),
        cr: parseFloat(obj.conversions / obj.impressions * 100),
        spent: obj.costs.toFixed(2),
        avg_cpa: (obj.costs / obj.conversions).toFixed(2),
        conversions: obj.conversions,
        costs: obj.costs,
        impressions: obj.impressions,
        interactions: obj.interactions
      };

      console.log(obj);

      obj.channels.forEach((channel) => {
        this.positions.push(channel);
      })

      obj.sub_categories.forEach((sub) => {
        sub.channels.forEach((channel) => {
          this.positions.push(channel);
        })
      })


    },
    prettyName(string) {
      if (string == 'safe') return 'Safe'
      if (string == 'waste') return 'Ad Waste'
      if (string == 'nonsafe') return 'Non Brand Safe'
      if (string == 'nonsuitable') return 'Non Brand Suitable'
      return string
    },
    checkReport(analysis) {
      console.log(analysis);

      let all = {};
      analysis.forEach((category) => {
        if (!all[category.inventory_type]) {
          all[category.inventory_type] = {
            name: this.prettyName(category.inventory_type),
            type: category.inventory_type,
            categories: [],
            count: 0,
          };
        }

        all[category.inventory_type].categories.push(category);
        all[category.inventory_type].count += category.channels_found;
      });

      // Convert 'all' object to an array
      const allArray = Object.values(all);

      // Calculate the percentage for each item in the array
      const totalChannels = this.reportData.analysis_metadata.channels_analysed;
      console.log(totalChannels);
      const allArrayWithPercentage = allArray.map((item) => ({
        ...item,
        // count: parseInt((item.count / totalChannels) * 100),
      }));


      console.log(allArrayWithPercentage);
      this.categories = allArrayWithPercentage;
      this.triggerTab(this.categories[0])
      // this.activateTab(this.categories[0])

    }

  },
  mounted() {
    this.checkReport(this.reportData.analysis);
  }
};
</script>

<style scoped>
.filament-color {
  color: #ff3b00
}

.bg-filament {
  /* background-color: #EDD3C4 */
  background-image: url('../assets/bg2.png');
  background-size: cover;
  border-bottom: 1px solid rgb(226, 226, 226);

  /* background-color: rgb(147, 197, 253); */
  /* background-image: radial-gradient(at 2% 4%, rgb(15, 23, 42) 0, transparent 68%), radial-gradient(at 57% 15%, rgb(254, 226, 226) 0, transparent 78%), radial-gradient(at 44% 59%, rgb(87, 83, 78) 0, transparent 68%), radial-gradient(at 33% 24%, rgb(67, 56, 202) 0, transparent 98%), radial-gradient(at 39% 3%, rgb(68, 64, 60) 0, transparent 31%), radial-gradient(at 73% 24%, rgb(88, 28, 135) 0, transparent 46%); */

}
</style>