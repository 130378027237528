<template>
  <div id="app" class="">
    <!-- <AuditReport v-if="loaded" :reportData="data" /> -->

    <NewAuditReport v-if="loaded" :reportData="data" />
  </div>
</template>

<script>
// import AuditReport from "./components/AuditReport.vue";
import NewAuditReport from "./components/NewAuditReport.vue"
// import metadata from "./data/mockdata.json";

import { EventBus } from './main';

export default {
  name: "App",
  components: {
    // AuditReport, 
    NewAuditReport
  },
  data() {
    return {
      loaded: false,
      data: {}
    };
  },
  methods: {
    async fetchData(id) {
    try {
      const jsonModule = await import(`@/data/${id}.json`);
      this.data = jsonModule.default;
      // this.data.analysis = this.metadata.analysis.sort(this.compare)
      this.loaded = true;
    } catch (error) {
      console.error('Error fetching JSON:', error);
    }
  },
    compare(a, b) {
      // If both items have a sort, compare them
      if ("sort" in a && "sort" in b) {
        return a.sort - b.sort;
      }

      // If only item 'a' has a sort, it should come first
      if ("sort" in a) {
        return -1;
      }

      // If only item 'b' has a sort, it should come first
      if ("sort" in b) {
        return 1;
      }

      // If neither item has a sort, they are considered equal
      return 0;
    },
  },
  mounted() {
    // this.data = metadata;
    
    EventBus.$on('hashchange', this.fetchData);
    
    // Fetch data based on the initial hash value
    const initialId = window.location.hash.substr(1);
    if (initialId) {
      this.fetchData(initialId);
    }
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
